@import "variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/utilities";

h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
);

h1, .h1 {
    font-size: 2rem;

    @include media-breakpoint-up(lg) {
        font-size: 4rem;
    }

    @media screen and (min-width: 1400px) {
        font-size: 3.75rem;
        line-height: 1.25;        
    }

    @media screen and (min-width: 1600px) {
        font-size: 3.75rem;
        line-height: 1.25;        
    }
}

h2, .h2 {
    font-size: 2rem;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        font-size: 4rem;
    }
}

h3, .h3 {
    font-size: 1.5rem;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        font-size: 3.125rem;
    }
}

body {
    margin: 0;
    font-family: $font-body;
    color: $black;
    font-size: 1rem;
    overflow-x: hidden !important;
}

img {
    width: auto;
    max-width: 100%;
}

small {
    &.extra-small {
        font-size: 0.5rem;
    }
}
a.text-secondary:hover, a.text-secondary:focus, a.text-white:hover, a.text-white:focus  {
    color: #fddc05 !important;
}
a{
    transition: 0.5s;
    &:hover{
        text-decoration: none !important;
        transition: 0.5s;
    }
}
.btn{
    transition: 0.5s;
    &:hover{
        border-radius: 50px !important;
        transition: 0.5s;
    }
}
.border-bottom {
    border-bottom: 1px solid #333333 !important;
}
.line-height-1{
    line-height: $line1;
}
.line-height-2{
    line-height: $line2;
}
.line-height-3{
    line-height: $line3;
}
.opacity{
    opacity: 0.8;
}
.banner {
    background: $secondary url("../images/banner.jpg") no-repeat center;
    height: 800px;
    padding: 1rem 0 1rem 26rem;
}
.right-banner{
    img{
        transition: 0.5s;
        &:hover{
            transform: rotate(45deg);
            transition: 0.5s;
        }
    }
}
.card-cta-1{
    background: url("../images/deal-1.png") no-repeat bottom right;
    padding: 4rem 15rem 4rem 4rem;
}
.card-cta-2{
    background: url("../images/deal-2.png") no-repeat bottom right;
    padding: 4rem 15rem 4rem 4rem;
}
.bookingform{
    .form-control, .input-group-addon{
        height: 45px;
        border-radius: 0;
        border: 0;
    }
}
.services-list{
    background: url("../images/service-list-bg.png") no-repeat right top;
    height: 819px;
}
.process{
    .box{
        height: 400px;
        &:before {
            content: ">";
            color: #b70e11;
            font-weight: bold;
            font-size: 25px;
            position: absolute;
            top: 47%;
            left: 102%; 
        }
    }
    .last-box{
        height: 400px;
    }
}
.about-us{
    background: url("../images/about-us-bg.jpg") no-repeat right bottom;
    background-size: cover;
    margin-top: -13rem;
}
.testimonials{
    background: #f5f5f5 url("../images/testimonials-bg.png") no-repeat left bottom;
}

// Slick Dots
.slick-dots{
    text-align: left;
}
.slick-dots li button:before{
    font-size: 2.5rem !important;
    color: #D32126 !important; 
}