@import "themes/main";

// Colours
$primary:   #b70e11  ;
$secondary: #111111;
$tertiary:  #B1B1B1 ;
$white:     #fff;
$blue:      #3a589d;
$gray:      #1C1C1C;
$lightgray: #9b9fad;
$black:     #131313;
$theme-colors: (
    "tertiary": $tertiary,
    "white": $white,
    "blue": $blue,
    "gray": $gray,
    "lightgray": $lightgray,
    "black": $black,
);

// Line Height
$line1: 0.7;
$line2: 1;
$line3: 1.15;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1760px,
) !default;

// Container sizing
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1160px,
    xxl: 1700px
) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (

    6:  ($spacer * 6),
    7:  ($spacer * 5),
    10: ($spacer * 10),
    13: ($spacer * 13),
    15: ($spacer * 15),
  ),
  $spacers
);