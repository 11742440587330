$font-roboto: 'Roboto', sans-serif;
$font-body: $font-roboto;
$font-proxima: proxima-nova, sans-serif;

.font-proxima{
    font-family: $font-proxima;
}
.font-roboto {
    font-family: $font-roboto;
}

// Font Weights
$font-weights: (
    'light':        300,
    'medium':       500,
    'semibold':     600,
    'bold':         700,
    'extrabold':    800,
    'black':        900,
);
@each $name, $weight in $font-weights {
    .font-weight-#{$name} {
        font-weight: $weight;
    }
}

// Font Sizes
$font-sizes: (
    'xs':   0.85rem,
    'sm':   1.165rem,
    'md':   1.25rem,
    'm':    1.5rem,
    'lg':   1.375rem,
    'ix':   1.75rem,
    'x':    1.875rem,
    'l':    2.25rem,
    'ixl':  2.5rem,
    'xl':   3.05rem,
    'xxl':  3.75rem,
    'xxxl': 4.25rem,
    '4xl':  8.125rem,
);
@each $name, $size in $font-sizes {
    .font-size-#{$name} {
        font-size: $size;
    }
}
