@media (max-width:450px){
  .card-cta-1, .card-cta-2 {
    background-position-x: 245% !important;
  }
}
@media (max-width:768px){
  .font-size-sm {
    font-size: 1rem;
  }
  .font-size-xl {
    font-size: 2rem;
  }
  .font-size-xxl {
    font-size: 1.85rem;
  }
  .font-size-xxxl {
    font-size: 2rem;
  }
  .font-size-m {
    font-size: 1rem;
  }
  .font-size-ixl {
    font-size: 2rem;
  }
  .services-list {
    background:none !important;
  }
  .box, .last-box {
    height: auto !important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    &:before {
      display:none;
  }
  }
}
@media (max-width:1024px){
  .font-size-ix {
    font-size: 1.25rem;
  }
  .top-banner-padding{
    padding-left: 3rem !important;
  }
  .service{
    background: #dfdedc !important;
    height: auto !important;
  }
  .top-image-1{
    width: 35%;
    bottom: 40% !important;
  }
  .banner{
    height: auto !important;
    padding: 1.5rem !important;
  }
  .card-cta-1, .card-cta-2 {
    padding: 2rem !important;
  }
  .slick-dots{
    text-align:center !important;
  }
  .services-list {
    height: auto !important;
    background-position-x: 120% !important;
  }
}
@media (min-width:1025px) and (max-width:1280px) {  
  .font-size-m {
    font-size: 1rem;
  }
  .font-size-md {
    font-size: 1.05rem;
  }
  .font-size-x {
    font-size: 1.25rem;
  }
  .font-size-ix {
    font-size: 1.25rem;
  }
  .font-size-ixl {
    font-size: 1.75rem;
  }
  .font-size-xl {
    font-size: 2rem;
  }
  .font-size-xxl {
    font-size: 2.5rem;
  }
  .services-list {
    background-position-x: 120% !important;
    height: 590px !important;
  }
  .banner{
    padding: 1rem 0 1rem 2rem !important;
    height: 770px !important; 
  }
  .card-cta-1, .card-cta-2 {
    padding: 4rem 9rem 4rem 4rem !important;
    background-position-x: 125% !important;
  }

} 
@media (min-width:1281px) and (max-width:1440px){
  .font-size-m {
    font-size: 1.05rem;
  }
  .font-size-md {
    font-size: 1.05rem;
  }
  .font-size-x {
    font-size: 1.5rem;
  }
  .font-size-ix {
    font-size: 1.25rem;
  }
  .font-size-ixl {
    font-size: 2rem;
  }
  .font-size-xl {
    font-size: 2.5rem;
  }
  .font-size-xxl {
    font-size: 2.75rem;
  }
  .services-list {
    background-position-x: 120% !important;
    height: 590px !important;
  }
  .banner{
    padding: 1rem 0 1rem 7rem !important;
    height: 770px !important; 
  }
  .card-cta-1, .card-cta-2 {
    padding: 4rem 9rem 4rem 4rem !important;
    background-position-x: 125% !important;
  }
}